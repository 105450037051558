import { withStyles } from '@material-ui/styles'
import { LoginForm } from 'ra-ui-materialui'
import React from 'react'
import { Login } from 'react-admin'
import ForgotPasswordDialog from './utils/forgotPassword'
import TwoFactorAuthenticationDialog from '../../utils/twoFactorAuthentication'

const styles = {
  login: {
    main: {
      backgroundImage: ' linear-gradient(to bottom,#1d1d1d, #3d3d3d, #2c2c2c);',
    },
    card: {
      background: ' linear-gradient(to bottom,#1d1d1d, #3d3d3d, #2c2c2c);',

      backgroundImage: 'url(/icons/veve-logo.png);',
      padding: '5px 0 15px 0',
      color: '#fff',
      border: 'none',
      backgroundRepeat: 'no-repeat;',
      backgroundPosition: 'top;',
      backgroundSize: '12rem',
    },
    avatar: {
      '& .MuiAvatar-root': {
        width: '0px !important',
      },
    },
  },
  form: {
    button: {
      height: '3em',
    },
  },
}

const MyLoginForm = withStyles(styles.form)(LoginForm)

const MyLogin = (props) => {
  const token = localStorage.getItem('username')
  if (token) {
    props.history.push('/users')
  }

  return (
    <Login {...props}>
      <MyLoginForm />
      <ForgotPasswordDialog {...props} />
      <TwoFactorAuthenticationDialog {...props} />
    </Login>
  )
}

export default withStyles(styles.login)(MyLogin)
