import React from 'react'
import {
  Edit,
  NumberInput,
  required,
  SimpleForm,
  TextInput,
  useShowController,
} from 'react-admin'
import ImgField from '../../utils/imageField'
import MediaCapture from '../../utils/imageUpload.jsx'
import CustomToolbar from '../../utils/customToolbar'

const ComicPagesEditTitle = ({ record = {} }) => (
  <span>Edit Comic Pages {`"${record.id}"`}</span>
)

const pageValidation = (files, allValues) => {
  if (files.length > 1) {
    return `single file upload only allowed`
  }
  const file = files[0]
  const basename = +file.name.replace(/\.[a-z0-9]+$/i, '')
  if (basename !== allValues.pageNumber) {
    return `Error: filename must match page number: ${file.name}`
  }
  return undefined
}

export const ComicPagesEdit = (props) => {
  const { record } = useShowController(props)

  return (
    <Edit title={<ComicPagesEditTitle />} {...props}>
      <SimpleForm toolbar={<CustomToolbar props={props} />}>
        <TextInput disabled label="Id" source="id" />
        <TextInput
          source="comicType.name"
          label="Comic type"
          validate={required()}
          disabled
        />
        <NumberInput source="pageNumber" validate={required()} />
        <ImgField source="thumbnailUrl" label="Image" sortable={false} />

        <MediaCapture
          label="Comic Pages"
          source="file"
          type="comic_page"
          endpoint="comic-pages"
          props={props}
          replace={true}
          validate={pageValidation}
        />
      </SimpleForm>
    </Edit>
  )
}
