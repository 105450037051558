import AllInboxIcon from '@material-ui/icons/AllInbox'
import AndroidIcon from '@material-ui/icons/Android'
import Reedem from '@material-ui/icons/Redeem'
import {
  default as AccessoriesIcon,
  default as CollectibleTypeIcon,
} from '@material-ui/icons/Apps'
import CollectibleIcon from '@material-ui/icons/ArtTrack'
import {
  default as AuctionBidIcon,
  default as PayoutIcon,
} from '@material-ui/icons/AttachMoney'
import ArtistIcon from '@material-ui/icons/Brush'
import {
  default as ComicPagesIcon,
  default as PromotionBannerIcon,
} from '@material-ui/icons/BurstMode'
import MarketingWindowIcon from '@material-ui/icons/ViewCarousel'
import LicensorIcon from '@material-ui/icons/Business'
import CommentIcon from '@material-ui/icons/Comment'
import WriterIcon from '@material-ui/icons/Create'
import CharacterIcon from '@material-ui/icons/DirectionsRun'
import ComicSeriesIcon from '@material-ui/icons/DynamicFeed'
import CustomizationCategoryIcon from '@material-ui/icons/Edit'
import StoreAuctionBidIcon from '@material-ui/icons/EmojiPeople'
import StoreAuctionIcon from '@material-ui/icons/Gavel'
import AccountMergeIcon from '@material-ui/icons/Group'
import ComicCoverIcon from '@material-ui/icons/Image'
import HashtagIcon from '@material-ui/icons/Label'
import BrandIcon from '@material-ui/icons/Layers'
import ComicTypeIcon from '@material-ui/icons/LibraryBooks'
import FeedIcon from '@material-ui/icons/ListAlt'
import PromoIcon from '@material-ui/icons/LocalOffer'
import ComicIcon from '@material-ui/icons/MenuBook'
import PayoutMethodIcon from '@material-ui/icons/MoneyOff'
import NotificationsIcon from '@material-ui/icons/NotificationsActive'
import PaletteIcon from '@material-ui/icons/Palette'
import TransactionIcon from '@material-ui/icons/Payment'
import UserIcon from '@material-ui/icons/People'
import ShowroomBackgroundIcon from '@material-ui/icons/PermMedia'
import MediaIcon from '@material-ui/icons/PhotoLibrary'
import UserGemBalanceLogIcon from '@material-ui/icons/PlaylistAddCheck'
import PublisherIcon from '@material-ui/icons/Print'
import MarketListingIcon from '@material-ui/icons/ShoppingBasket'
import ShowroomIcon from '@material-ui/icons/ThreeDRotation'
import SeriesIcon from '@material-ui/icons/Tv'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import AccountDeletionIcon from '@material-ui/icons/Remove'

import {
  default as LoginBackgroundIcon,
  default as ShowroomBackgroundTypeIcon,
} from '@material-ui/icons/Wallpaper'
import { MenuItem, MultiLevelMenu } from '@react-admin/ra-navigation'
import { WithPermissions, useCanAccess } from '@react-admin/ra-rbac'
import * as React from 'react'
import isWaitlistV2Enabled from './waitlistV2'

export const Menu = () => {
  const { canAccess: canAccessMarketListings } = useCanAccess({
    action: 'list',
    resource: 'market-listings',
  })
  const { canAccess: canAccessStoreAuctions } = useCanAccess({
    action: 'list',
    resource: 'store-auctions',
  })
  const { canAccess: canAccessShowrooms } = useCanAccess({
    action: 'list',
    resource: 'showrooms',
  })
  const { canAccess: canAccessShowroomBackground } = useCanAccess({
    action: 'list',
    resource: 'showroom-background',
  })
  const { canAccess: canAccessLicensors } = useCanAccess({
    action: 'list',
    resource: 'licensors',
  })
  const { canAccess: canAccessPublishers } = useCanAccess({
    action: 'list',
    resource: 'publishers',
  })
  const { canAccess: canAccessComics } = useCanAccess({
    action: 'list',
    resource: 'comics',
  })

  const { canAccess: canAccessPayoutProfiles } = useCanAccess({
    action: 'list',
    resource: 'payout-profiles',
  })

  const { canAccess: canAccessPayoutMethods } = useCanAccess({
    action: 'list',
    resource: 'payout-methods',
  })

  const { canAccess: canAccessPayoutTransactions } = useCanAccess({
    action: 'list',
    resource: 'payments/payouts',
  })

  const { canAccess: canAccessPayoutNiumRfi } = useCanAccess({
    action: 'list',
    resource: 'payments/payout-nium-rfi',
  })

  const defaultPayoutPage = canAccessPayoutProfiles
    ? 'payout-profiles'
    : canAccessPayoutMethods
    ? 'payout-methods'
    : canAccessPayoutTransactions
    ? 'payments/payouts'
    : undefined

  return (
    <MultiLevelMenu>
      <WithPermissions action="list" resource="users">
        <MenuItem name="users" to="/" label="Users" icon={<UserIcon />}>
          <WithPermissions action="*" resource="bulk">
            <MenuItem
              name="bulk-transfer"
              to="/bulk/create"
              label="Bulk Transfer"
              icon={<UserIcon />}
            />
          </WithPermissions>
          <MenuItem
            name="kyc"
            to="/kyc"
            label="Kyc"
            icon={<VerifiedUserIcon />}
          />
          <MenuItem
            name="user-gem-balance-log"
            to="/user-gem-balance-log"
            label="GEM balance log"
            icon={<UserGemBalanceLogIcon />}
          />
          <MenuItem
            name="account-deletion"
            to="/account-deletion"
            label="Account deletion"
            icon={<AccountDeletionIcon />}
          />
          <MenuItem
            name="account-merge"
            to="/account-merge"
            label="Account merge"
            icon={<AccountMergeIcon />}
          />
        </MenuItem>
      </WithPermissions>

      {defaultPayoutPage && (
        <MenuItem
          name="payoutProfiles"
          to={defaultPayoutPage}
          label="Payout"
          icon={<PayoutIcon />}
        >
          {canAccessPayoutProfiles && (
            <MenuItem
              name="payoutProfiles"
              to="/payout-profiles"
              label="Payout Profiles"
              icon={<PayoutIcon />}
            />
          )}
          {canAccessPayoutMethods && (
            <MenuItem
              name="payoutMethods"
              to="/payout-methods"
              label="Payout Methods"
              icon={<PayoutMethodIcon />}
            />
          )}
          {canAccessPayoutTransactions && (
            <MenuItem
              name="payoutTransactions"
              to="/payments/payouts"
              label="Payout Transactions"
              icon={<PayoutIcon />}
            />
          )}
          {canAccessPayoutNiumRfi && (
            <MenuItem
              name="payoutRfi"
              to="/payments/payout-nium-rfi"
              label="Payout Nium Rfi"
              icon={<PayoutIcon />}
            />
          )}
        </MenuItem>
      )}

      {(canAccessLicensors && (
        <MenuItem
          name="licensors"
          to="/licensors"
          label="Digital Collectibles"
          icon={<CollectibleIcon />}
        >
          <MenuItem
            name="licensors"
            to="/licensors"
            label="Licensors"
            icon={<LicensorIcon />}
          />
          <MenuItem
            name="brands"
            to="/brands"
            label="Brands"
            icon={<BrandIcon />}
          />
          <MenuItem
            name="series"
            to="/series"
            label="Series"
            icon={<SeriesIcon />}
          />
          <MenuItem
            name="collectibleTypes"
            to="/collectible-types"
            label="Collectible Types"
            icon={<CollectibleTypeIcon />}
          />
          <WithPermissions action="list" resource="collectibles">
            <MenuItem
              name="collectibles"
              to="/collectibles"
              label="Collectibles"
              icon={<CollectibleIcon />}
            />
          </WithPermissions>
          <MenuItem
            name="collectibleTypeConvertMapping"
            to="/collectible-type-convert-mapping"
            label="Redemption Rewards"
            icon={<CollectibleTypeIcon />}
          />
        </MenuItem>
      )) || (
        <MenuItem
          name="collectibles"
          to="/collectibles"
          label="Collectibles"
          icon={<CollectibleIcon />}
        />
      )}

      <WithPermissions action="list" resource="publishers">
        <MenuItem
          name="publishers"
          to="/publishers"
          label="Digital Comics"
          icon={<ComicIcon />}
        >
          <MenuItem
            name="publishers"
            to="/publishers"
            label="Publishers"
            icon={<PublisherIcon />}
          />
          <MenuItem
            name="comic-series"
            to="/comic-series"
            label="Comic Series"
            icon={<ComicSeriesIcon />}
          />
          <MenuItem
            name="comic-types"
            to="/comic-types"
            label="Comic Types"
            icon={<ComicTypeIcon />}
          />
          <MenuItem
            name="comics"
            to="/comics"
            label="Comics"
            icon={<ComicIcon />}
          />
          <MenuItem
            name="comic-covers"
            to="/comic-covers"
            label="Comic Covers"
            icon={<ComicCoverIcon />}
          />
          <MenuItem
            name="comic-pages"
            to="/comic-pages"
            label="Comic Pages"
            icon={<ComicPagesIcon />}
          />
          <MenuItem
            name="artists"
            to="/artists"
            label="Artists"
            icon={<ArtistIcon />}
          />
          <MenuItem
            name="writers"
            to="/writers"
            label="Writers"
            icon={<WriterIcon />}
          />
          <MenuItem
            name="characters"
            to="/characters"
            label="Characters"
            icon={<CharacterIcon />}
          />
          <MenuItem
            name="custom-groups"
            to="/custom-groups"
            label="Custom groups"
            icon={<AllInboxIcon />}
          />
          <MenuItem
            name="promotional-reservation"
            to="/promotional-reservation"
            label="Promotion Reserve"
            icon={<Reedem />}
          />
          <MenuItem
            name="marketing-window"
            to="/marketing-window"
            label="Marketing Windows"
            icon={<MarketingWindowIcon />}
          />
        </MenuItem>
      </WithPermissions>
      {canAccessComics && !canAccessPublishers && (
        <MenuItem
          name="comics"
          to="/comics"
          label="Comics"
          icon={<ComicIcon />}
        />
      )}

      <WithPermissions action="list" resource="accessory-type">
        <MenuItem
          name="accessory-type"
          to="/accessory-type"
          label="Accessories"
          icon={<AccessoriesIcon />}
        >
          <MenuItem
            name="accessory-type"
            to="/accessory-type"
            label="Accessory Type"
            icon={<AccessoriesIcon />}
          />
          <MenuItem
            name="accessory"
            to="/accessory"
            label="Accessory"
            icon={<ShowroomBackgroundIcon />}
          />

          <MenuItem
            name="accessory-balances"
            to="/accessory-balance"
            label="Accessory Balances"
            icon={<CollectibleIcon />}
          />
        </MenuItem>
      </WithPermissions>

      {(canAccessShowrooms && (
        <MenuItem
          name="showrooms"
          to="/showrooms"
          label="Showrooms"
          icon={<ShowroomIcon />}
        >
          <MenuItem
            name="showrooms"
            to="/showrooms"
            label="Showroom"
            icon={<ShowroomIcon />}
          />
          <MenuItem
            name="showroom-background-type"
            to="/showroom-background-type"
            label="Showroom BG Type"
            icon={<ShowroomBackgroundTypeIcon />}
          />
          <MenuItem
            name="showroom-background"
            to="/showroom-background"
            label="Showroom BG"
            icon={<ShowroomBackgroundIcon />}
          />
        </MenuItem>
      )) ||
        (canAccessShowroomBackground && (
          <MenuItem
            name="showroom-background-type"
            to="/showroom-background-type"
            label="Showroom BG Type"
            icon={<ShowroomBackgroundTypeIcon />}
          >
            <MenuItem
              name="showroom-background-type"
              to="/showroom-background-type"
              label="Showroom BG Type"
              icon={<ShowroomBackgroundTypeIcon />}
            />
            <MenuItem
              name="showroom-background"
              to="/showroom-background"
              label="Showroom BG"
              icon={<ShowroomBackgroundIcon />}
            />
          </MenuItem>
        ))}

      {canAccessLicensors && canAccessPublishers && isWaitlistV2Enabled() && (
        <MenuItem
          name="waitlist-segments"
          to="/waitlist-segments"
          label="Waitlist Segments"
          icon={<ComicCoverIcon />}
        />
      )}

      {canAccessStoreAuctions && (
        <MenuItem
          name="storeAuction"
          to="/store-auctions"
          label="Store auctions"
          icon={<StoreAuctionIcon />}
        >
          <MenuItem
            name="storeAuction"
            to="/store-auctions"
            label="Store auctions"
            icon={<StoreAuctionIcon />}
          />
          <MenuItem
            name="storeAuctionBid"
            to="/store-auction-bids"
            label="Store Auction Bids"
            icon={<StoreAuctionBidIcon />}
          />
        </MenuItem>
      )}
      {(canAccessMarketListings && (
        <MenuItem
          name="marketListing"
          to="/market-listings"
          label="Market"
          icon={<MarketListingIcon />}
        >
          <MenuItem
            name="marketListing"
            to="/market-listings"
            label="Market Listing"
            icon={<MarketListingIcon />}
          />
          <MenuItem
            name="auctions"
            to="/auctions"
            label="Auction Bids"
            icon={<AuctionBidIcon />}
          />
        </MenuItem>
      )) || (
        <MenuItem
          name="auctions"
          to="/auctions"
          label="Auction Bids"
          icon={<AuctionBidIcon />}
        />
      )}

      <WithPermissions action="list" resource="customization-category">
        <MenuItem
          name="customizationCategory"
          to="/customization-category"
          label="Customization Category"
          icon={<CustomizationCategoryIcon />}
        />
      </WithPermissions>

      <WithPermissions action="list" resource="login-background">
        <MenuItem
          name="login-background"
          to="/login-background"
          label="Login Background"
          icon={<LoginBackgroundIcon />}
        />
      </WithPermissions>
      <MenuItem name="media" to="/media" label="Media" icon={<MediaIcon />} />

      <WithPermissions action="list" resource="feed">
        <MenuItem name="feed" to="/feed" label="Feed" icon={<FeedIcon />} />
      </WithPermissions>

      <WithPermissions action="list" resource="transactions">
        <MenuItem
          name="transactions"
          to="/transactions"
          label="Transactions"
          icon={<TransactionIcon />}
        />
      </WithPermissions>
      <WithPermissions action="list" resource="master-collector/user-summary">
        <MenuItem
          name="master-collector/user-summary"
          to="/master-collector/user-summary"
          label="MCP"
          icon={<TransactionIcon />}
        >
          <MenuItem
            name="master-collector/user-summary"
            to="/master-collector/user-summary"
            label="User summary"
            icon={<TransactionIcon />}
          />
          <WithPermissions
            action="list"
            resource="master-collector/transaction"
          >
            <MenuItem
              name="master-collector/transaction"
              to="/master-collector/transaction"
              label="Transactions"
              icon={<TransactionIcon />}
            />
          </WithPermissions>
          <WithPermissions
            action="list"
            resource="master-collector/activity-point-value"
          >
            <MenuItem
              name="master-collector/activity-point-value"
              to="/master-collector/activity-point-value"
              label="Activity point value"
              icon={<TransactionIcon />}
            />
          </WithPermissions>
          <WithPermissions action="list" resource="master-collector/levels">
            <MenuItem
              name="master-collector/levels"
              to="/master-collector/levels"
              label="Levels"
              icon={<TransactionIcon />}
            />
          </WithPermissions>
          <WithPermissions action="list" resource="master-collector/ranks">
            <MenuItem
              name="master-collector/ranks"
              to="/master-collector/ranks"
              label="Ranks"
              icon={<TransactionIcon />}
            />
          </WithPermissions>
        </MenuItem>
      </WithPermissions>
      <MenuItem
        name="rewards"
        to="/rewards/pending-process"
        label="Rewards"
        icon={<TransactionIcon />}
      >
        <MenuItem
          name="rewards/pending-process"
          to="/rewards/pending-process"
          label="Pending Process"
          icon={<TransactionIcon />}
        />
      </MenuItem>

      <WithPermissions action="list" resource="reward/rules">
        <MenuItem
          name="reward/rules"
          to="/reward/rules"
          label="Craft Rules"
          icon={<TransactionIcon />}
        >
          <WithPermissions action="create" resource="reward/rules">
            <MenuItem
              name="reward/rules/create/raw"
              to="/reward/rules/create/raw"
              label="Create using JSON"
              icon={<TransactionIcon />}
            />
          </WithPermissions>
        </MenuItem>
      </WithPermissions>
      <WithPermissions action="list" resource="theme">
        <MenuItem
          name="theme"
          to="/theme"
          label="Themes"
          icon={<PaletteIcon />}
        />
      </WithPermissions>
      <WithPermissions action="list" resource="comments">
        <MenuItem
          name="comments"
          to="/comments"
          label="Comments"
          icon={<CommentIcon />}
        />
      </WithPermissions>
      <WithPermissions action="list" resource="hashtags">
        <MenuItem
          name="hashtags"
          to="/hashtags"
          label="Hashtags"
          icon={<HashtagIcon />}
        />
      </WithPermissions>
      <WithPermissions action="list" resource="promos">
        <MenuItem
          name="promos"
          to="/promos"
          label="Promos"
          icon={<PromoIcon />}
        />
      </WithPermissions>
      <WithPermissions action="list" resource="promotionBanners">
        <MenuItem
          name="promotionBanners"
          to="/promotion-banners"
          label="Promotion Banners"
          icon={<PromotionBannerIcon />}
        />
      </WithPermissions>
      <MenuItem
        name="notification"
        to="/notification"
        label="Notifications"
        icon={<NotificationsIcon />}
      >
        <WithPermissions action="create" resource="notification/push">
          <MenuItem
            name="notification/push/create"
            to="/notification/push/create"
            label="Send Push Notification"
            icon={<NotificationsIcon />}
          />
        </WithPermissions>
      </MenuItem>
      <WithPermissions action="*" resource="/kasada/events">
        <MenuItem
          name="kasada"
          to="/kasada/events"
          label="Kasada"
          icon={<AndroidIcon />}
        />
      </WithPermissions>
    </MultiLevelMenu>
  )
}
