import React from 'react'
import { withStyles } from '@material-ui/styles'
import { ImageField } from 'react-admin'

const styles = {
  image: { width: '50px' },
}
const ImgField = withStyles(styles)(({ classes, permissions, ...props }) => (
  <ImageField classes={classes} {...props} />
))

export default ImgField
