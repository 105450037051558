import React from 'react'
import {
  NumberField,
  Show,
  SimpleShowLayout,
  TextField,
  useShowController,
} from 'react-admin'
import ImgField from '../../utils/imageField'
import DateField from '../../utils/dateField'
import { globalStyles } from '../../App'

const ComicPagesViewTitle = ({ record = {} }) => (
  <span>View Comic Pages {`"${record.id}"`}</span>
)

export const ComicPagesView = (props) => {
  const { record } = useShowController(props)
  if (record && record.artists) {
    const artistIds = record.artists.map((c) => c.id) || []
    record['artistIds'] = artistIds
  }
  const classes = globalStyles()
  return (
    <Show title={<ComicPagesViewTitle />} {...props}>
      <SimpleShowLayout>
        <div className={classes.cuDates}>
          <DateField source="createdAt" />
          <br />
          <DateField source="updatedAt" />
        </div>
        <TextField label="Id" source="id" />
        <TextField source="comicType.name" label="Comic Type Name" />
        <NumberField source="pageNumber" />
        <ImgField source="url" label="" sortable={false} />
      </SimpleShowLayout>
    </Show>
  )
}
