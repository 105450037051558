import { WithPermissions } from '@react-admin/ra-rbac'
import React from 'react'
import { DeleteButton, SaveButton, Toolbar } from 'react-admin'

const CustomToolbar = (allProps) => {
  // clean up errors from console (because of invalid DOM props)
  const {
    hasList,
    hasShow,
    hasCreate,
    hasEdit,
    hideDelete,
    pristine,
    invalid,
    ...props
  } = allProps
  if (hideDelete) {
    return (
      <Toolbar {...props}>
        <SaveButton disabled={pristine || invalid} {...props} />
      </Toolbar>
    )
  }
  return (
    <Toolbar {...props}>
      <SaveButton disabled={pristine || invalid} {...props} />
      <WithPermissions action="delete" resource={props.resource}>
        <DeleteButton mutationMode="undoable" />
      </WithPermissions>
    </Toolbar>
  )
}

export default CustomToolbar
