import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core'
import React, { useRef } from 'react'
import { Button as RAButton } from 'react-admin'
import { connect } from 'react-redux'
import { defaultHttpHeaders } from '../../../config/defaultHeaders'

const ForgotPasswordDialog = (props) => {
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [success, setSuccess] = React.useState('')
  const [error, setError] = React.useState('')

  const email = useRef(0)

  const handleClose = () => {
    setOpen(false)
  }

  const handleProceed = async () => {
    setLoading(true)

    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/auth/password/forgot`,
      {
        method: 'POST',
        body: JSON.stringify({ email: email.current.value }),
        headers: defaultHttpHeaders,
        credentials: 'include',
      }
    )
    setLoading(false)
    const returnedData = await response.json()
    if (response.ok) {
      setSuccess(`Success! password reset mail sent.`)
    } else {
      setError(`Error ${returnedData.message[0]}`)
    }
  }

  const openImportDialog = () => {
    setOpen(true)
  }
  return (
    <>
      <RAButton
        color="primary"
        component="span"
        label={'Forgot Password?'}
        onClick={openImportDialog}
        style={{ color: '#f44336' }}
      ></RAButton>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Forgot Password</DialogTitle>
        <DialogContent>
          <div
            id="alert-dialog-description"
            style={{ fontFamily: 'sans-serif' }}
          >
            <TextField label="Admin email" required inputRef={email} />

            {!!error && (
              <p style={{ marginTop: '20px', color: '#f44336' }}>{error}</p>
            )}

            {!!success && (
              <p style={{ marginTop: '20px', color: 'green' }}>{success}</p>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            <span>Close</span>
          </Button>
          <Button
            onClick={handleProceed}
            color="secondary"
            variant="contained"
            disabled={loading || error !== '' || success !== ''}
          >
            {loading && <CircularProgress size={18} thickness={2} />}
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export { ForgotPasswordDialog }
export default connect()(ForgotPasswordDialog)
