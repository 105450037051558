import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import fetch from 'cross-fetch'
import debounce from 'lodash/debounce'
import React, { Component } from 'react'
import { Field } from 'react-final-form'
import { defaultHttpHeaders } from '../config/defaultHeaders'

const { 'content-type': remove, ...headers } = defaultHttpHeaders
class SearchAutoComplete extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      options: [],
      selectedValue: null,
    }
    this.loading = this.state.open && this.state.options.length === 0
    this.setOpen = (value) => this.setState({ open: value })
    this.handleInput = debounce(this.fetchSuggestedTags.bind(this), 250)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange = (e, value) => {
    return this.props.input.onChange(e.target.textContent, value)
  }

  async fetchSuggestedTags(inputValue) {
    if (inputValue.length > 0 && inputValue.search('#') === -1) {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/admin/${
          this.props.props.resource
        }/suggest/${inputValue.split(' ').join('')}`,
        {
          headers,
          credentials: 'include',
        }
      )
      const data = await response.json()
      if (data && Array.isArray(data)) {
        const hashtags = data.map((hashtag) => `#${hashtag}`)
        this.setState({ options: hashtags })
      }
    }
  }

  render() {
    return (
      <Autocomplete
        id="searchQuery"
        style={{ width: 300 }}
        open={this.state.open}
        onOpen={() => {
          this.setOpen(true)
        }}
        onClose={() => {
          this.setOpen(false)
        }}
        autoSelect={true}
        options={this.state.options}
        loading={this.loading}
        onInputChange={(event, newValue) => {
          this.handleInput(newValue)
        }}
        onChange={this.handleChange}
        name="searchQuery"
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label="Search"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
              }}
            />
          )
        }}
      />
    )
  }
}
const SearchInput = (props) => (
  <span>
    <Field
      name="searchQuery"
      component={SearchAutoComplete}
      props={props}
      type="text"
    />
  </span>
)

export default SearchInput
