import React from 'react'
import {
  Datagrid,
  DateField,
  EditButton,
  Filter,
  List,
  NumberField,
  TextField,
  TextInput,
} from 'react-admin'
import { defaultPageLimit } from '../../config/defaultPageLimit'
import SearchInput from '../../utils/search'
import ImgField from '../../utils/imageField'

const ComicPagesFilter = (props) => (
  <Filter {...props}>
    <SearchInput label="Search" source="searchQuery" props={props} />
    <TextInput label="Comic Type" source="comicTypeId" />
  </Filter>
)

export const ComicPagesList = (props) => (
  <List
    title="Comic Pages"
    bulkActionButtons={false}
    perPage={defaultPageLimit}
    filters={<ComicPagesFilter />}
    sort={{ field: 'comicType.name', order: 'ASC' }}
    {...props}
  >
    <Datagrid rowClick="show">
      <TextField
        source="comicType.name"
        label="Comic Type Name"
        sortable={true}
      />
      <ImgField source="thumbnailUrl" label="Image" sortable={false} />
      <NumberField source="pageNumber" />
      <DateField source="createdAt" sortable={false} />
      <EditButton />
    </Datagrid>
  </List>
)
