import { createTheme, makeStyles } from '@material-ui/core/styles'
import { Resource } from '@react-admin/ra-rbac'
import simpleRestProvider from 'ra-data-simple-rest'
import React, { Component } from 'react'
import { Admin } from 'react-admin'
import { Route } from 'react-router-dom'
import {
  AccessoryBalanceList,
  AccessoryList,
  AccessoryTypeCreate,
  AccessoryTypeEdit,
  AccessoryTypeList,
  AccessoryTypeView,
  AccessoryView,
  AccountDeletionEdit,
  AccountDeletionList,
  AccountDeletionView,
  AccountMergeList,
  AccountMergeView,
  ArtistCreate,
  ArtistEdit,
  ArtistList,
  ArtistView,
  AuctionBidEdit,
  AuctionBidList,
  AuctionBidView,
  BrandCreate,
  BrandEdit,
  BrandList,
  BrandView,
  BulkTransfer,
  CharacterCreate,
  CharacterEdit,
  CharacterList,
  CharacterView,
  CollectibleEdit,
  CollectibleList,
  CollectibleTypeConvertMappingCreate,
  CollectibleTypeCreate,
  CollectibleTypeEdit,
  CollectibleTypeList,
  CollectibleTypeView,
  CollectibleView,
  ComicCoversEdit,
  ComicCoversList,
  ComicCoversView,
  ComicList,
  ComicPagesList,
  ComicPagesView,
  ComicSeriesCreate,
  ComicSeriesEdit,
  ComicSeriesList,
  ComicSeriesView,
  ComicTypeCreate,
  ComicTypeEdit,
  ComicTypeList,
  ComicTypeView,
  ComicView,
  CommentEdit,
  CommentList,
  CommentView,
  CustomGroupCreate,
  CustomGroupEdit,
  CustomGroupList,
  CustomizationCategoryCreate,
  CustomizationCategoryEdit,
  CustomizationCategoryList,
  CustomizationCategoryView,
  FeedEdit,
  FeedList,
  FeedView,
  HashtagCreate,
  HashtagEdit,
  HashtagList,
  HashtagView,
  KasadaDropEdit,
  KasadaEventCreate,
  KasadaEventEdit,
  KasadaEventList,
  KycList,
  KycView,
  LicensorCreate,
  LicensorEdit,
  LicensorList,
  LicensorView,
  LoginBackgroundCreate,
  LoginBackgroundList,
  LoginBackgroundView,
  MarketingWindowCreate,
  MarketingWindowEdit,
  MarketingWindowList,
  MarketingWindowView,
  MarketListingEdit,
  MarketListingList,
  MarketListingView,
  McpActivityPointValueEdit,
  McpActivityPointValueList,
  McpLevelsList,
  McpRanksList,
  McpTransactionList,
  McpUserSummaryList,
  McpUserSummaryView,
  MediaCreate,
  MediaEdit,
  MediaList,
  MediaView,
  NoteCreate,
  NoteEdit,
  NotificationList,
  NotificationView,
  PayoutMethodEdit,
  PayoutMethodList,
  PayoutMethodView,
  PayoutProfileEdit,
  PayoutProfileList,
  PayoutProfileView,
  PayoutRfiList,
  PayoutRfiResolve,
  PayoutTransactionList,
  PayoutTransactionView,
  PromoList,
  PromotionalReservationCreate,
  PromotionalReservationEdit,
  PromotionalReservationList,
  PromotionalReservationView,
  PromotionBannerCreate,
  PromotionBannerEdit,
  PromotionBannerList,
  PromotionBannerView,
  PromoView,
  PublisherCreate,
  PublisherEdit,
  PublisherList,
  PublisherView,
  RawRewardRuleCreate,
  RewardPendingProcessList,
  RewardRuleCreate,
  RewardRuleEdit,
  RewardRuleList,
  RewardRuleView,
  RewardsPendingProcessCreate,
  RewardsPendingProcessView,
  SendPushNotification,
  SeriesCreate,
  SeriesEdit,
  SeriesList,
  SeriesView,
  ShowroomBackgroundList,
  ShowroomBackgroundTypeCreate,
  ShowroomBackgroundTypeEdit,
  ShowroomBackgroundTypeList,
  ShowroomBackgroundTypeView,
  ShowroomBackgroundView,
  ShowroomList,
  ShowroomView,
  StoreAuctionBidEdit,
  StoreAuctionBidList,
  StoreAuctionBidView,
  StoreAuctionEdit,
  StoreAuctionList,
  StoreAuctionView,
  ThemeCreate,
  ThemeEdit,
  ThemeList,
  ThemeView,
  TransactionList,
  TransactionView,
  UserCreate,
  UserEdit,
  UserGemBalanceList,
  UserList,
  UserLoginHistoryList,
  UserView,
  WaitlistSegmentsEdit,
  WaitlistSegmentsList,
  WaitlistSegmentsView,
  WriterCreate,
  WriterEdit,
  WriterList,
  WriterView,
} from './components'
import { CollectibleTypeConvertMappingList } from './modules/collectibleTypeConvertMapping/collectibleTypeConvertMappingList'
import { ComicPagesEdit } from './modules/comicPages'
import Login from './modules/login/login'
import { payoutRfiDataProvider } from './modules/payoutRfi/payoutRfiDataProvider'
import authProvider from './services/authProvider'
import graphqlProvider from './services/graphqlProvider'
import { combineDataProviders } from './utils/combineDataProviders'
import CustomLayout from './utils/customLayout'
import { extendedRestProvider } from './utils/extendedRestProvider'
import { BaseURL, httpClient } from './utils/httpClient'
import notFound from './utils/notFound'
import creatorPlatformDataProvider from './services/creatorPlatformDataProvider'

const theme = createTheme({
  typography: {
    fontSize: '16',
    body1: {
      fontSize: '1rem',
    },
  },
  palette: {
    type: 'dark',
    primary: {
      main: '#0ecef2',
      contrastText: '#2c2c2c',
    },
    secondary: {
      light: '#115293',
      main: '#424242',
      contrastText: '#fff',
    },
  },
  overrides: {
    RaSidebar: {
      root: {
        '& .MuiSvgIcon-root': {
          width: '.75em',
          height: '.75em',
        },
      },
      fixed: {
        zIndex: 1200,
      },
    },
    RaMenuItem: {
      link: {
        '&:hover': {
          color: '#2c2c2c',
        },
      },
      active: {
        color: '#0ecef2',
      },
    },
    RaLabeled: {
      value: {
        paddingTop: '.15em',
        marginBottom: '.25em',
        whiteSpace: 'pre-wrap'
      },
    },
    RaFormInput: {
      input: {
        marginTop: 0,
        marginBottom: 0,
      },
    },
    RaDatagrid: {
      rowCell: {
        whiteSpace: 'pre-wrap'
      }
    }
  },
})

export const globalStyles = makeStyles({
  cuDates: {
    float: 'right',
    '& > .MuiFormControl-root': {
      marginRight: '20px',
    },
  },
})

const baseDataProvider = simpleRestProvider(BaseURL, httpClient)
const defaultDataProvider = {
  ...baseDataProvider,
  getBlindboxReservation: (resource, params) =>
    httpClient(`${BaseURL}/${resource}/${params.id}/blindbox-reservation`).then(
      ({ json }) => ({ data: { ...json } })
    ),
  createBlindboxReservation: (resource, params) =>
    httpClient(`${BaseURL}/${resource}/${params.id}/blindbox-reservation`, {
      method: 'POST',
    }).then(() => ({ data: { success: true } })),
  approveComicType: (resource, params) =>
    httpClient(`${BaseURL}/${resource}/${params.id}/approve`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(() => ({ data: { success: true } })),
}
const dataProviderMcpActivityPointValue = extendedRestProvider(
  `${BaseURL}`,
  httpClient,
  'Content-Range',
  'type'
)
const dataProviderHasUserIdAsKey = extendedRestProvider(
  `${BaseURL}`,
  httpClient,
  'Content-Range',
  'userId'
)
const dataProviderMcpLevels = extendedRestProvider(
  `${BaseURL}`,
  httpClient,
  'Content-Range',
  'level'
)
const dataProviderMcpRanks = extendedRestProvider(
  `${BaseURL}`,
  httpClient,
  'Content-Range',
  'rankNumber'
)

const dataProviderCreatorPlatform = creatorPlatformDataProvider(
  `${BaseURL}`,
  httpClient
)

const customRoutes = [
  <Route
    exact
    path="/reward/rules/create/raw"
    name="reward/rules/create/raw"
    render={(routeProps) => (
      <RawRewardRuleCreate
        resource="reward/rules"
        basePath={routeProps.match.url}
      />
    )}
  />,
  <Route
    exact
    path="/notification/push/create"
    name="notification/push/create"
    render={(routeProps) => (
      <SendPushNotification
        resource="notification/push"
        basePath={routeProps.match.url}
      />
    )}
  />,
]

class App extends Component {
  constructor() {
    super()
    this.state = { graphQlDataProvider: null }
  }
  componentDidMount() {
    graphqlProvider().then((dataProvider) =>
      this.setState({ graphQlDataProvider: dataProvider })
    )
  }
  render() {
    const { graphQlDataProvider } = this.state
    const dataProvider = combineDataProviders((resource) => {
      switch (resource) {
        case 'publishers':
        case 'comic-series':
        case 'comic-types':
        case 'comic-covers':
        case 'comic-pages':
        case 'artists':
        case 'characters':
        case 'writers':
          return dataProviderCreatorPlatform
        case 'master-collector/activity-point-value':
          return dataProviderMcpActivityPointValue
        case 'master-collector/user-summary':
          return dataProviderHasUserIdAsKey
        case 'master-collector/levels':
          return dataProviderMcpLevels
        case 'master-collector/ranks':
          return dataProviderMcpRanks
        case 'payments/payout-nium-rfi':
          return payoutRfiDataProvider
        case 'accessory-balance':
          return graphQlDataProvider
        case 'accessory-type':
          return graphQlDataProvider
        case 'rewards/pending-process':
          return graphQlDataProvider
        default:
          return defaultDataProvider
      }
    })
    return (
      <Admin
        customRoutes={customRoutes}
        authProvider={authProvider}
        dataProvider={dataProvider}
        loginPage={Login}
        theme={theme}
        catchAll={notFound}
        layout={CustomLayout}
      >
        <Resource
          name="users"
          list={UserList}
          create={UserCreate}
          edit={UserEdit}
          show={UserView}
        />
        <Resource name="login-history" list={UserLoginHistoryList} />
        <Resource name="notes" create={NoteCreate} edit={NoteEdit} />
        <Resource name="bulk" create={BulkTransfer} />
        <Resource
          name="licensors"
          list={LicensorList}
          edit={LicensorEdit}
          show={LicensorView}
          create={LicensorCreate}
        />
        <Resource
          name="brands"
          list={BrandList}
          edit={BrandEdit}
          show={BrandView}
          create={BrandCreate}
        />
        <Resource
          name="series"
          list={SeriesList}
          edit={SeriesEdit}
          show={SeriesView}
          create={SeriesCreate}
        />
        <Resource
          name="collectible-types"
          list={CollectibleTypeList}
          edit={CollectibleTypeEdit}
          create={CollectibleTypeCreate}
          show={CollectibleTypeView}
        />
        <Resource
          name="reward/rules"
          list={RewardRuleList}
          edit={RewardRuleEdit}
          create={RewardRuleCreate}
          show={RewardRuleView}
        />
        <Resource
          name="collectible-type-convert-mapping"
          list={CollectibleTypeConvertMappingList}
          create={CollectibleTypeConvertMappingCreate}
        />
        <Resource
          name="collectibles"
          list={CollectibleList}
          show={CollectibleView}
          edit={CollectibleEdit}
        />
        <Resource
          name="customization-category"
          list={CustomizationCategoryList}
          show={CustomizationCategoryView}
          edit={CustomizationCategoryEdit}
          create={CustomizationCategoryCreate}
        />
        <Resource
          name="market-listings"
          list={MarketListingList}
          edit={MarketListingEdit}
          show={MarketListingView}
        />
        <Resource
          name="auctions"
          list={AuctionBidList}
          edit={AuctionBidEdit}
          show={AuctionBidView}
        />
        <Resource
          name="login-background"
          list={LoginBackgroundList}
          show={LoginBackgroundView}
          create={LoginBackgroundCreate}
        />
        <Resource
          name="media"
          create={MediaCreate}
          list={MediaList}
          show={MediaView}
          edit={MediaEdit}
        />
        <Resource name="feed" list={FeedList} show={FeedView} edit={FeedEdit} />
        <Resource
          name="transactions"
          list={TransactionList}
          show={TransactionView}
        />
        <Resource
          name="master-collector/activity-point-value"
          list={McpActivityPointValueList}
          edit={McpActivityPointValueEdit}
        />
        <Resource
          name="master-collector/transaction"
          list={McpTransactionList}
        />
        <Resource
          name="master-collector/user-summary"
          list={McpUserSummaryList}
          show={McpUserSummaryView}
        />
        <Resource name="master-collector/levels" list={McpLevelsList} />
        <Resource name="master-collector/ranks" list={McpRanksList} />
        <Resource name="user-gem-balance-log" list={UserGemBalanceList} />
        <Resource
          name="account-deletion"
          list={AccountDeletionList}
          show={AccountDeletionView}
          edit={AccountDeletionEdit}
        />
        <Resource name="account-deletion-step" />
        <Resource
          name="account-merge"
          list={AccountMergeList}
          show={AccountMergeView}
        />
        <Resource name="account-merge-step" />
        <Resource
          name="theme"
          list={ThemeList}
          show={ThemeView}
          edit={ThemeEdit}
          create={ThemeCreate}
        />
        <Resource
          name="comments"
          list={CommentList}
          show={CommentView}
          edit={CommentEdit}
        />
        <Resource name="showrooms" list={ShowroomList} show={ShowroomView} />
        <Resource
          name="showroom-background-type"
          list={ShowroomBackgroundTypeList}
          show={ShowroomBackgroundTypeView}
          edit={ShowroomBackgroundTypeEdit}
          create={ShowroomBackgroundTypeCreate}
        />
        <Resource
          name="showroom-background"
          list={ShowroomBackgroundList}
          show={ShowroomBackgroundView}
        />
        <Resource
          name="accessory-type"
          list={AccessoryTypeList}
          show={AccessoryTypeView}
          edit={AccessoryTypeEdit}
          create={AccessoryTypeCreate}
        />
        <Resource name="accessory" list={AccessoryList} show={AccessoryView} />

        <Resource name={'accessory-balance'} list={AccessoryBalanceList} />

        <Resource
          name="hashtags"
          list={HashtagList}
          show={HashtagView}
          edit={HashtagEdit}
          create={HashtagCreate}
        />

        <Resource
          name="notification"
          list={NotificationList}
          show={NotificationView}
        />

        <Resource name="notification/push" create={SendPushNotification} />

        <Resource
          name="publishers"
          list={PublisherList}
          edit={PublisherEdit}
          show={PublisherView}
          create={PublisherCreate}
        />

        <Resource
          name="comic-series"
          list={ComicSeriesList}
          edit={ComicSeriesEdit}
          show={ComicSeriesView}
          create={ComicSeriesCreate}
        />

        <Resource
          name="writers"
          list={WriterList}
          edit={WriterEdit}
          show={WriterView}
          create={WriterCreate}
        />

        <Resource
          name="characters"
          list={CharacterList}
          edit={CharacterEdit}
          show={CharacterView}
          create={CharacterCreate}
        />

        <Resource
          name="artists"
          list={ArtistList}
          edit={ArtistEdit}
          show={ArtistView}
          create={ArtistCreate}
        />

        <Resource
          name="comic-types"
          list={ComicTypeList}
          edit={ComicTypeEdit}
          show={ComicTypeView}
          create={ComicTypeCreate}
        />

        <Resource name="comics" list={ComicList} show={ComicView} />

        <Resource
          name="comic-covers"
          list={ComicCoversList}
          show={ComicCoversView}
          edit={ComicCoversEdit}
        />

        <Resource
          name="comic-pages"
          list={ComicPagesList}
          show={ComicPagesView}
          edit={ComicPagesEdit}
        />

        <Resource
          name="marketing-window"
          show={MarketingWindowView}
          edit={MarketingWindowEdit}
          list={MarketingWindowList}
          create={MarketingWindowCreate}
        />

        <Resource name="promos" list={PromoList} show={PromoView} />
        <Resource
          name="promotion-banners"
          list={PromotionBannerList}
          show={PromotionBannerView}
          edit={PromotionBannerEdit}
          create={PromotionBannerCreate}
        />

        <Resource
          name="store-auctions"
          list={StoreAuctionList}
          show={StoreAuctionView}
          edit={StoreAuctionEdit}
        />
        <Resource
          name="store-auction-bids"
          list={StoreAuctionBidList}
          show={StoreAuctionBidView}
          edit={StoreAuctionBidEdit}
        />

        <Resource name="kyc" list={KycList} show={KycView} />
        <Resource
          name="payout-profiles"
          list={PayoutProfileList}
          show={PayoutProfileView}
          edit={PayoutProfileEdit}
        />
        <Resource
          name="payout-methods"
          list={PayoutMethodList}
          show={PayoutMethodView}
          edit={PayoutMethodEdit}
        />
        <Resource
          name="payments/payouts"
          list={PayoutTransactionList}
          show={PayoutTransactionView}
        />
        <Resource
          name="payments/payout-nium-rfi"
          list={PayoutRfiList}
          edit={PayoutRfiResolve}
        />
        <Resource
          name="kasada/events"
          list={KasadaEventList}
          edit={KasadaEventEdit}
          create={KasadaEventCreate}
        />
        <Resource name="kasada/drop" edit={KasadaDropEdit} />
        <Resource
          name="custom-groups"
          list={CustomGroupList}
          edit={CustomGroupEdit}
          create={CustomGroupCreate}
        />
        <Resource name="custom-groups/items" />

        <Resource
          name="rewards/pending-process"
          create={RewardsPendingProcessCreate}
          list={RewardPendingProcessList}
          show={RewardsPendingProcessView}
        />

        <Resource
          name="waitlist-segments"
          list={WaitlistSegmentsList}
          show={WaitlistSegmentsView}
          edit={WaitlistSegmentsEdit}
        />

        <Resource
          name="promotional-reservation"
          list={PromotionalReservationList}
          edit={PromotionalReservationEdit}
          create={PromotionalReservationCreate}
          show={PromotionalReservationView}
        />
      </Admin>
    )
  }
}

export default App
