import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from '@material-ui/core'
import PasswordIcon from '@material-ui/icons/Lock'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import React from 'react'
import { MenuItemLink } from 'react-admin'
import { connect } from 'react-redux'
import { defaultHttpHeaders } from '../../../config/defaultHeaders'
import otpService from '../../../services/otpService'
import TwoFactorAuthenticationDialog from '../../../utils/twoFactorAuthentication'

const ChangePasswordDialog = React.forwardRef((props) => {
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [success, setSuccess] = React.useState('')
  const [error, setError] = React.useState('')
  const [showPassword, setShowPassword] = React.useState({
    old: false,
    new: false,
    confirm: false,
  })
  const [oldError, setOldError] = React.useState(false)
  const [newError, setNewError] = React.useState(false)
  const [confirmError, setConfirmError] = React.useState(false)
  const [oldHelper, setOldHelper] = React.useState('')
  const [confirmHelper, setConfirmHelper] = React.useState('')
  const oldPasswordRef = React.useRef('')
  const newPasswordRef = React.useRef('')
  const confirmPasswordRef = React.useRef('')

  const stopPropagationForTab = (event) => {
    if (event.key === 'Tab') {
      event.stopPropagation()
    }
  }

  const handleClickShowPassword = (field) => {
    showPassword[field] = !showPassword[field]
    setShowPassword({ ...showPassword })
  }

  const handleChange = (field) => {
    const oldPassword = oldPasswordRef.current.value
    const newPassword = newPasswordRef.current.value
    const confirmPassword = confirmPasswordRef.current.value
    if (field === 'old') {
      if (oldPassword.length === 0) {
        setOldError(true)
        setOldHelper('This field is required')
      } else {
        setOldError(false)
        setOldHelper('')
      }
    } else {
      if (newPassword !== confirmPassword && confirmPassword.length) {
        setConfirmError(true)
        setConfirmHelper('New passwords do not match')
      } else {
        setConfirmError(false)
        setConfirmHelper('')
      }
    }
  }

  const validate = () => {
    const errors = []
    const oldPassword = oldPasswordRef.current.value
    const newPassword = newPasswordRef.current.value
    const confirmPassword = confirmPasswordRef.current.value

    if (oldPassword.length === 0) {
      setOldError(true)
      setOldHelper('This field is required')
    }

    if (newPassword !== confirmPassword) {
      errors.push('New passwords do not match')
    }
    if (newPassword.length < 8 || newPassword.length > 40) {
      errors.push('Passwords must be between 8 to 40 characters long')
    }
    if (!/[a-z]/.test(newPassword)) {
      errors.push('Passwords must contain at least one lower case letter')
    }
    if (!/[A-Z]/.test(newPassword)) {
      errors.push('Passwords must contain at least one upper case letter')
    }
    if (!/\d/.test(newPassword)) {
      errors.push('Passwords must contain at least one number')
    }
    if (!/[.,:;!@#$%^&*?(){}[\]<>+=\-_`~/\\]/.test(newPassword)) {
      errors.push(
        'Passwords must contain at least one of these special characters: .,:;!@#$%^&*?(){}[]<>+=-_`~/\\'
      )
    }

    if (errors.length) {
      setNewError(true)
      setConfirmError(true)
      setConfirmHelper('')
      setLoading(false)
      setError(`Errors:\n • ${errors.join('\n • ')}`)
      return false
    } else {
      setNewError(false)
      setConfirmError(false)
      setOldError(false)
      setOldHelper('')
      setConfirmHelper('')
      setError('')
      return true
    }
  }

  const handleClose = () => {
    setOpen(false)
    setError('')
    setSuccess('')
    setLoading(false)
    window.dispatchEvent(new CustomEvent('closeOtpModal'))
  }

  const handleProceed = async (ev) => {
    ev.preventDefault()
    setLoading(true)
    setError('')

    if (!validate()) {
      return false
    }

    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/auth/password/change`,
      {
        method: 'POST',
        body: JSON.stringify({
          oldPassword: oldPasswordRef.current.value,
          newPassword: newPasswordRef.current.value,
        }),
        headers: defaultHttpHeaders,
        credentials: 'include',
      }
    ).catch((err) => {
      setLoading(false)
      setError(`Error ${err.message}`)
    })

    if (response.ok) {
      setLoading(false)
      setSuccess('Success! Your password was updated')
    } else {
      const reply = await response.json()
      if (reply.message.includes('2FA needed to complete')) {
        await otpService(
          undefined,
          async () => {
            await handleProceed(ev)
          },
          {}
        )
      } else {
        setLoading(false)
        const messages = Array.isArray(reply.message)
          ? reply.message.join('\n • ')
          : reply.message
        setError(`Error updating password:\n • ${messages}`)
      }
    }
  }

  const openImportDialog = () => {
    setOpen(true)
  }

  return (
    <>
      <MenuItemLink
        to="#"
        primaryText="Change password"
        leftIcon={<PasswordIcon />}
        onClick={openImportDialog}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        onKeyDown={stopPropagationForTab}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form onSubmit={(ev) => handleProceed(ev)}>
          <DialogTitle id="alert-dialog-title">Change Password</DialogTitle>
          <DialogContent>
            <div
              id="alert-dialog-description"
              style={{ fontFamily: 'sans-serif' }}
            >
              <TextField
                inputRef={oldPasswordRef}
                variant="filled"
                type={showPassword.old ? `text` : `password`}
                size="small"
                label="Current Password"
                onChange={() => handleChange('old')}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() => handleClickShowPassword('old')}
                      edge="end"
                    >
                      {showPassword.old ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  ),
                }}
                required
                error={oldError}
                helperText={oldHelper}
              />
              <br />
              <br />
              <TextField
                inputRef={newPasswordRef}
                variant="filled"
                type={showPassword.new ? `text` : `password`}
                size="small"
                label="New Password"
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() => handleClickShowPassword('new')}
                      edge="end"
                    >
                      {showPassword.new ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  ),
                }}
                error={newError}
              />
              <br />
              <br />
              <TextField
                inputRef={confirmPasswordRef}
                variant="filled"
                type={showPassword.confirm ? `text` : `password`}
                size="small"
                label="Confirm New Password"
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() => handleClickShowPassword('confirm')}
                      edge="end"
                    >
                      {showPassword.confirm ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  ),
                }}
                error={confirmError}
                helperText={confirmHelper}
              />

              {!!error && (
                <p
                  style={{
                    marginTop: '20px',
                    color: 'red',
                    whiteSpace: 'pre-line',
                  }}
                >
                  {error}
                </p>
              )}

              {!!success && (
                <p style={{ marginTop: '20px', color: 'green' }}>{success}</p>
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>
              <span>Close</span>
            </Button>
            <Button
              type="submit"
              onClick={handleProceed}
              color="secondary"
              variant="contained"
              disabled={loading || success !== '' || oldError || confirmError}
            >
              {loading && <CircularProgress size={18} thickness={2} />}
              Proceed
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <TwoFactorAuthenticationDialog {...props} />
    </>
  )
})

export { ChangePasswordDialog }
export default connect()(ChangePasswordDialog)
