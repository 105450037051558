import CircularProgress from '@material-ui/core/CircularProgress'
import React from 'react'
import Loadable from 'react-loadable'

// User components
export const UserList = Loadable({
  loader: () => import('./modules/user/userList'),
  render(loaded, props) {
    let Component = loaded.UserList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const UserCreate = Loadable({
  loader: () => import('./modules/user/userCreate'),
  render(loaded, props) {
    let Component = loaded.UserCreate
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const NoteCreate = Loadable({
  loader: () => import('./modules/user/noteCreate'),
  render(loaded, props) {
    let Component = loaded.NoteCreate
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const NoteEdit = Loadable({
  loader: () => import('./modules/user/noteEdit'),
  render(loaded, props) {
    let Component = loaded.NoteEdit
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const UserEdit = Loadable({
  loader: () => import('./modules/user/userEdit'),
  render(loaded, props) {
    let Component = loaded.UserEdit
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const UserView = Loadable({
  loader: () => import('./modules/user/userView'),
  render(loaded, props) {
    let Component = loaded.UserView
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const BulkTransfer = Loadable({
  loader: () => import('./modules/user/bulkTransfer'),
  render(loaded, props) {
    let Component = loaded.BulkTransfer
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

// Licensor components
export const LicensorList = Loadable({
  loader: () => import('./modules/licensor/licensorList'),
  render(loaded, props) {
    let Component = loaded.LicensorList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const LicensorCreate = Loadable({
  loader: () => import('./modules/licensor/licensorCreate'),
  render(loaded, props) {
    let Component = loaded.LicensorCreate
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const LicensorEdit = Loadable({
  loader: () => import('./modules/licensor/licensorEdit'),
  render(loaded, props) {
    let Component = loaded.LicensorEdit
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const LicensorView = Loadable({
  loader: () => import('./modules/licensor/licensorView'),
  render(loaded, props) {
    let Component = loaded.LicensorView
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

// Brand components
export const BrandList = Loadable({
  loader: () => import('./modules/brand/brandList'),
  render(loaded, props) {
    let Component = loaded.BrandList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const BrandCreate = Loadable({
  loader: () => import('./modules/brand/brandCreate'),
  render(loaded, props) {
    let Component = loaded.BrandCreate
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const BrandEdit = Loadable({
  loader: () => import('./modules/brand/brandEdit'),
  render(loaded, props) {
    let Component = loaded.BrandEdit
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const BrandView = Loadable({
  loader: () => import('./modules/brand/brandView'),
  render(loaded, props) {
    let Component = loaded.BrandView
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

// Series components
export const SeriesList = Loadable({
  loader: () => import('./modules/series/seriesList'),
  render(loaded, props) {
    let Component = loaded.SeriesList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const SeriesCreate = Loadable({
  loader: () => import('./modules/series/seriesCreate'),
  render(loaded, props) {
    let Component = loaded.SeriesCreate
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const SeriesEdit = Loadable({
  loader: () => import('./modules/series/seriesEdit'),
  render(loaded, props) {
    let Component = loaded.SeriesEdit
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const SeriesView = Loadable({
  loader: () => import('./modules/series/seriesView'),
  render(loaded, props) {
    let Component = loaded.SeriesView
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

// CollectibleType components
export const CollectibleTypeList = Loadable({
  loader: () => import('./modules/collectibleType/collectibleTypeList'),
  render(loaded, props) {
    let Component = loaded.CollectibleTypeList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const CollectibleTypeCreate = Loadable({
  loader: () => import('./modules/collectibleType/collectibleTypeCreate'),
  render(loaded, props) {
    let Component = loaded.CollectibleTypeCreate
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const CollectibleTypeEdit = Loadable({
  loader: () => import('./modules/collectibleType/collectibleTypeEdit'),
  render(loaded, props) {
    let Component = loaded.CollectibleTypeEdit
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const CollectibleTypeView = Loadable({
  loader: () => import('./modules/collectibleType/collectibleTypeView'),
  render(loaded, props) {
    let Component = loaded.CollectibleTypeView
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

// RewardRule components
export const RewardRuleList = Loadable({
  loader: () => import('./modules/rewardRule/rewardRuleList'),
  render(loaded, props) {
    let Component = loaded.RewardRuleList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const RewardRuleCreate = Loadable({
  loader: () => import('./modules/rewardRule/rewardRuleCreate'),
  render(loaded, props) {
    let Component = loaded.RewardRuleCreate
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const RawRewardRuleCreate = Loadable({
  loader: () => import('./modules/rewardRule/rawRewardRuleCreate'),
  render(loaded, props) {
    let Component = loaded.RawRewardRuleCreate
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const RewardRuleEdit = Loadable({
  loader: () => import('./modules/rewardRule/rewardRuleEdit'),
  render(loaded, props) {
    let Component = loaded.RewardRuleEdit
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const RewardRuleView = Loadable({
  loader: () => import('./modules/rewardRule/rewardRuleView'),
  render(loaded, props) {
    let Component = loaded.RewardRuleView
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

// CollectibleTypeConvertMapping components
export const CollectibleTypeConvertMappingList = Loadable({
  loader: () =>
    import(
      './modules/collectibleTypeConvertMapping/collectibleTypeConvertMappingList'
    ),
  render(loaded, props) {
    let Component = loaded.CollectibleTypeConvertMappingList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const CollectibleTypeConvertMappingCreate = Loadable({
  loader: () =>
    import(
      './modules/collectibleTypeConvertMapping/collectibleTypeConvertMappingCreate'
    ),
  render(loaded, props) {
    let Component = loaded.CollectibleTypeConvertMappingCreate
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

// Collectible components
export const CollectibleList = Loadable({
  loader: () => import('./modules/collectible/collectibleList'),
  render(loaded, props) {
    let Component = loaded.CollectibleList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const CollectibleView = Loadable({
  loader: () => import('./modules/collectible/collectibleView'),
  render(loaded, props) {
    let Component = loaded.CollectibleView
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const CollectibleEdit = Loadable({
  loader: () => import('./modules/collectible/collectibleEdit'),
  render(loaded, props) {
    let Component = loaded.CollectibleEdit
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

// CustomizationCategory components

export const CustomizationCategoryCreate = Loadable({
  loader: () =>
    import('./modules/customizationCategory/customizationCategoryCreate'),
  render(loaded, props) {
    let Component = loaded.CustomizationCategoryCreate
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const CustomizationCategoryEdit = Loadable({
  loader: () =>
    import('./modules/customizationCategory/customizationCategoryEdit'),
  render(loaded, props) {
    let Component = loaded.CustomizationCategoryEdit
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const CustomizationCategoryList = Loadable({
  loader: () =>
    import('./modules/customizationCategory/customizationCategoryList'),
  render(loaded, props) {
    let Component = loaded.CustomizationCategoryList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const CustomizationCategoryView = Loadable({
  loader: () =>
    import('./modules/customizationCategory/customizationCategoryView'),
  render(loaded, props) {
    let Component = loaded.CustomizationCategoryView
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

// Store Auction components
export const StoreAuctionList = Loadable({
  loader: () => import('./modules/storeAuction/storeAuctionList'),
  render(loaded, props) {
    let Component = loaded.StoreAuctionList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const StoreAuctionView = Loadable({
  loader: () => import('./modules/storeAuction/storeAuctionView'),
  render(loaded, props) {
    let Component = loaded.StoreAuctionView
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const StoreAuctionEdit = Loadable({
  loader: () => import('./modules/storeAuction/storeAuctionEdit'),
  render(loaded, props) {
    let Component = loaded.StoreAuctionEdit
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const StoreAuctionBidList = Loadable({
  loader: () => import('./modules/storeAuction/storeAuctionBidList'),
  render(loaded, props) {
    let Component = loaded.StoreAuctionBidList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const StoreAuctionBidView = Loadable({
  loader: () => import('./modules/storeAuction/storeAuctionBidView'),
  render(loaded, props) {
    let Component = loaded.StoreAuctionBidView
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const StoreAuctionBidEdit = Loadable({
  loader: () => import('./modules/storeAuction/storeAuctionBidEdit'),
  render(loaded, props) {
    let Component = loaded.StoreAuctionBidEdit
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

// MarketListing components
export const MarketListingList = Loadable({
  loader: () => import('./modules/market/marketListingList'),
  render(loaded, props) {
    let Component = loaded.MarketListingList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const MarketListingView = Loadable({
  loader: () => import('./modules/market/marketListingView'),
  render(loaded, props) {
    let Component = loaded.MarketListingView
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const MarketListingEdit = Loadable({
  loader: () => import('./modules/market/marketListingEdit'),
  render(loaded, props) {
    let Component = loaded.MarketListingEdit
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

// AuctionBid components
export const AuctionBidList = Loadable({
  loader: () => import('./modules/auctionBid/auctionBidList'),
  render(loaded, props) {
    let Component = loaded.AuctionBidList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const AuctionBidView = Loadable({
  loader: () => import('./modules/auctionBid/auctionBidView'),
  render(loaded, props) {
    let Component = loaded.AuctionBidView
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const AuctionBidEdit = Loadable({
  loader: () => import('./modules/auctionBid/auctionBidEdit'),
  render(loaded, props) {
    let Component = loaded.AuctionBidEdit
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

// User login history

export const UserLoginHistoryList = Loadable({
  loader: () => import('./modules/userLoginHistory/userLoginHistoryList'),
  render(loaded, props) {
    let Component = loaded.UserLoginHistoryList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

// Login background
export const LoginBackgroundCreate = Loadable({
  loader: () => import('./modules/loginBackground/loginBackgroundCreate'),
  render(loaded, props) {
    let Component = loaded.LoginBackgroundCreate
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const LoginBackgroundList = Loadable({
  loader: () => import('./modules/loginBackground/loginBackgroundList'),
  render(loaded, props) {
    let Component = loaded.LoginBackgroundList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const LoginBackgroundView = Loadable({
  loader: () => import('./modules/loginBackground/loginBackgroundView'),
  render(loaded, props) {
    let Component = loaded.LoginBackgroundView
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

// Media

export const MediaCreate = Loadable({
  loader: () => import('./modules/media/mediaCreate'),
  render(loaded, props) {
    let Component = loaded.MediaCreate
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const MediaEdit = Loadable({
  loader: () => import('./modules/media/mediaEdit'),
  render(loaded, props) {
    let Component = loaded.MediaEdit
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const MediaList = Loadable({
  loader: () => import('./modules/media/mediaList'),
  render(loaded, props) {
    let Component = loaded.MediaList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const MediaView = Loadable({
  loader: () => import('./modules/media/mediaView'),
  render(loaded, props) {
    let Component = loaded.MediaView
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

// Feed
export const FeedEdit = Loadable({
  loader: () => import('./modules/feed/feedEdit'),
  render(loaded, props) {
    let Component = loaded.FeedEdit
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const FeedList = Loadable({
  loader: () => import('./modules/feed/feedList'),
  render(loaded, props) {
    let Component = loaded.FeedList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const FeedView = Loadable({
  loader: () => import('./modules/feed/feedView'),
  render(loaded, props) {
    let Component = loaded.FeedView
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

// Transaction components

export const TransactionList = Loadable({
  loader: () => import('./modules/transaction/transactionList'),
  render(loaded, props) {
    let Component = loaded.TransactionList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const TransactionView = Loadable({
  loader: () => import('./modules/transaction/transactionView'),
  render(loaded, props) {
    let Component = loaded.TransactionView
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

// User GEM balance components

export const UserGemBalanceList = Loadable({
  loader: () => import('./modules/userGemBalance/userGemBalanceList'),
  render(loaded, props) {
    let Component = loaded.UserGemBalanceList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

// Account Deletion components

export const AccountDeletionList = Loadable({
  loader: () => import('./modules/accountDeletion/accountDeletionList'),
  render(loaded, props) {
    let Component = loaded.AccountDeletionList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const AccountDeletionView = Loadable({
  loader: () => import('./modules/accountDeletion/accountDeletionView'),
  render(loaded, props) {
    let Component = loaded.AccountDeletionView
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const AccountDeletionEdit = Loadable({
  loader: () => import('./modules/accountDeletion/accountDeletionEdit'),
  render(loaded, props) {
    let Component = loaded.AccountDeletionEdit
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

// Account Merge components

export const AccountMergeList = Loadable({
  loader: () => import('./modules/accountMerge/accountMergeList'),
  render(loaded, props) {
    let Component = loaded.AccountMergeList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const AccountMergeView = Loadable({
  loader: () => import('./modules/accountMerge/accountMergeView'),
  render(loaded, props) {
    let Component = loaded.AccountMergeView
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

// Theme components
export const ThemeList = Loadable({
  loader: () => import('./modules/theme/themeList'),
  render(loaded, props) {
    let Component = loaded.ThemeList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const ThemeCreate = Loadable({
  loader: () => import('./modules/theme/themeCreate'),
  render(loaded, props) {
    let Component = loaded.ThemeCreate
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const ThemeEdit = Loadable({
  loader: () => import('./modules/theme/themeEdit'),
  render(loaded, props) {
    let Component = loaded.ThemeEdit
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const ThemeView = Loadable({
  loader: () => import('./modules/theme/themeView'),
  render(loaded, props) {
    let Component = loaded.ThemeView
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

// Comment

export const CommentEdit = Loadable({
  loader: () => import('./modules/comment/commentEdit'),
  render(loaded, props) {
    let Component = loaded.CommentEdit
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const CommentList = Loadable({
  loader: () => import('./modules/comment/commentList'),
  render(loaded, props) {
    let Component = loaded.CommentList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const CommentView = Loadable({
  loader: () => import('./modules/comment/commentView'),
  render(loaded, props) {
    let Component = loaded.CommentView
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

// Showroom

export const ShowroomList = Loadable({
  loader: () => import('./modules/showroom/showroomList'),
  render(loaded, props) {
    let Component = loaded.ShowroomList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const ShowroomView = Loadable({
  loader: () => import('./modules/showroom/showroomView'),
  render(loaded, props) {
    let Component = loaded.ShowroomView
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

// ShowroomBackgroundType components
export const ShowroomBackgroundTypeList = Loadable({
  loader: () =>
    import('./modules/showroomBackground/showroomBackgroundTypeList'),
  render(loaded, props) {
    let Component = loaded.ShowroomBackgroundTypeList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const ShowroomBackgroundTypeCreate = Loadable({
  loader: () => import('./modules/showroomBackground/showroomBackgroundCreate'),
  render(loaded, props) {
    let Component = loaded.ShowroomBackgroundTypeCreate
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const ShowroomBackgroundTypeEdit = Loadable({
  loader: () =>
    import('./modules/showroomBackground/showroomBackgroundTypeEdit'),
  render(loaded, props) {
    let Component = loaded.ShowroomBackgroundTypeEdit
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const ShowroomBackgroundTypeView = Loadable({
  loader: () =>
    import('./modules/showroomBackground/showroomBackgroundTypeView'),
  render(loaded, props) {
    let Component = loaded.ShowroomBackgroundTypeView
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

// ShowroomBackground components
export const ShowroomBackgroundList = Loadable({
  loader: () => import('./modules/showroomBackground/showroomBackgroundList'),
  render(loaded, props) {
    let Component = loaded.ShowroomBackgroundList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const ShowroomBackgroundView = Loadable({
  loader: () => import('./modules/showroomBackground/showroomBackgroundView'),
  render(loaded, props) {
    let Component = loaded.ShowroomBackgroundView
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

// AccessoryType components
export const AccessoryTypeList = Loadable({
  loader: () => import('./modules/accessory/accessoryTypeList'),
  render(loaded, props) {
    let Component = loaded.AccessoryTypeList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const AccessoryTypeCreate = Loadable({
  loader: () => import('./modules/accessory/accessoryTypeCreate'),
  render(loaded, props) {
    let Component = loaded.AccessoryTypeCreate
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const AccessoryTypeEdit = Loadable({
  loader: () => import('./modules/accessory/accessoryTypeEdit'),
  render(loaded, props) {
    let Component = loaded.AccessoryTypeEdit
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const AccessoryTypeView = Loadable({
  loader: () => import('./modules/accessory/accessoryTypeView'),
  render(loaded, props) {
    let Component = loaded.AccessoryTypeView
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

// Accessory components
export const AccessoryList = Loadable({
  loader: () => import('./modules/accessory/accessoryList'),
  render(loaded, props) {
    let Component = loaded.AccessoryList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const AccessoryView = Loadable({
  loader: () => import('./modules/accessory/accessoryView'),
  render(loaded, props) {
    let Component = loaded.AccessoryView
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const AccessoryBalanceList = Loadable({
  loader: () => import('./modules/accessory/accessoryBalanceList'),
  render(loaded, props) {
    let Component = loaded.AccessoryBalanceList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

// Hashtag components
export const HashtagList = Loadable({
  loader: () => import('./modules/hashtag/hashtagList'),
  render(loaded, props) {
    let Component = loaded.HashtagList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const HashtagCreate = Loadable({
  loader: () => import('./modules/hashtag/hashtagCreate'),
  render(loaded, props) {
    let Component = loaded.HashtagCreate
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const HashtagEdit = Loadable({
  loader: () => import('./modules/hashtag/hashtagEdit'),
  render(loaded, props) {
    let Component = loaded.HashtagEdit
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const HashtagView = Loadable({
  loader: () => import('./modules/hashtag/hashtagView'),
  render(loaded, props) {
    let Component = loaded.HashtagView
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

// Notification components
export const NotificationList = Loadable({
  loader: () => import('./modules/notification/notificationList'),
  render(loaded, props) {
    let Component = loaded.NotificationList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const NotificationView = Loadable({
  loader: () => import('./modules/notification/notificationView'),
  render(loaded, props) {
    let Component = loaded.NotificationView
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const SendPushNotification = Loadable({
  loader: () => import('./modules/notification/sendPushNotification'),
  render(loaded, props) {
    let Component = loaded.SendPushNotification
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

// Publisher components
export const PublisherList = Loadable({
  loader: () => import('./modules/publisher/publisherList'),
  render(loaded, props) {
    let Component = loaded.PublisherList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const PublisherCreate = Loadable({
  loader: () => import('./modules/publisher/publisherCreate'),
  render(loaded, props) {
    let Component = loaded.PublisherCreate
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const PublisherEdit = Loadable({
  loader: () => import('./modules/publisher/publisherEdit'),
  render(loaded, props) {
    let Component = loaded.PublisherEdit
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const PublisherView = Loadable({
  loader: () => import('./modules/publisher/publisherView'),
  render(loaded, props) {
    let Component = loaded.PublisherView
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

// Comic Series components
export const ComicSeriesList = Loadable({
  loader: () => import('./modules/comicSeries/comicSeriesList'),
  render(loaded, props) {
    let Component = loaded.ComicSeriesList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const ComicSeriesCreate = Loadable({
  loader: () => import('./modules/comicSeries/comicSeriesCreate'),
  render(loaded, props) {
    let Component = loaded.ComicSeriesCreate
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const ComicSeriesEdit = Loadable({
  loader: () => import('./modules/comicSeries/comicSeriesEdit'),
  render(loaded, props) {
    let Component = loaded.ComicSeriesEdit
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const ComicSeriesView = Loadable({
  loader: () => import('./modules/comicSeries/comicSeriesView'),
  render(loaded, props) {
    let Component = loaded.ComicSeriesView
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

// Writer components
export const WriterList = Loadable({
  loader: () => import('./modules/writer/writerList'),
  render(loaded, props) {
    let Component = loaded.WriterList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const WriterCreate = Loadable({
  loader: () => import('./modules/writer/writerCreate'),
  render(loaded, props) {
    let Component = loaded.WriterCreate
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const WriterEdit = Loadable({
  loader: () => import('./modules/writer/writerEdit'),
  render(loaded, props) {
    let Component = loaded.WriterEdit
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const WriterView = Loadable({
  loader: () => import('./modules/writer/writerView'),
  render(loaded, props) {
    let Component = loaded.WriterView
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

// Character components
export const CharacterList = Loadable({
  loader: () => import('./modules/character/characterList'),
  render(loaded, props) {
    let Component = loaded.CharacterList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const CharacterCreate = Loadable({
  loader: () => import('./modules/character/characterCreate'),
  render(loaded, props) {
    let Component = loaded.CharacterCreate
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const CharacterEdit = Loadable({
  loader: () => import('./modules/character/characterEdit'),
  render(loaded, props) {
    let Component = loaded.CharacterEdit
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const CharacterView = Loadable({
  loader: () => import('./modules/character/characterView'),
  render(loaded, props) {
    let Component = loaded.CharacterView
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

// Artist components
export const ArtistList = Loadable({
  loader: () => import('./modules/artist/artistList'),
  render(loaded, props) {
    let Component = loaded.ArtistList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const ArtistCreate = Loadable({
  loader: () => import('./modules/artist/artistCreate'),
  render(loaded, props) {
    let Component = loaded.ArtistCreate
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const ArtistEdit = Loadable({
  loader: () => import('./modules/artist/artistEdit'),
  render(loaded, props) {
    let Component = loaded.ArtistEdit
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const ArtistView = Loadable({
  loader: () => import('./modules/artist/artistView'),
  render(loaded, props) {
    let Component = loaded.ArtistView
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

// Comic Type components
export const ComicTypeList = Loadable({
  loader: () => import('./modules/comicType/comicTypeList'),
  render(loaded, props) {
    let Component = loaded.ComicTypeList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const ComicTypeCreate = Loadable({
  loader: () => import('./modules/comicType/comicTypeCreate'),
  render(loaded, props) {
    let Component = loaded.ComicTypeCreate
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const ComicTypeEdit = Loadable({
  loader: () => import('./modules/comicType/comicTypeEdit'),
  render(loaded, props) {
    let Component = loaded.ComicTypeEdit
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const ComicTypeView = Loadable({
  loader: () => import('./modules/comicType/comicTypeView'),
  render(loaded, props) {
    let Component = loaded.ComicTypeView
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

// Comic components
export const ComicList = Loadable({
  loader: () => import('./modules/comic/comicList'),
  render(loaded, props) {
    let Component = loaded.ComicList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const ComicView = Loadable({
  loader: () => import('./modules/comic/comicView'),
  render(loaded, props) {
    let Component = loaded.ComicView
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

// Comic Cover components
export const ComicCoversList = Loadable({
  loader: () => import('./modules/comicCovers/comicCoversList'),
  render(loaded, props) {
    let Component = loaded.ComicCoversList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const ComicCoversEdit = Loadable({
  loader: () => import('./modules/comicCovers/comicCoversEdit'),
  render(loaded, props) {
    let Component = loaded.ComicCoversEdit
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const ComicCoversView = Loadable({
  loader: () => import('./modules/comicCovers/comicCoversView'),
  render(loaded, props) {
    let Component = loaded.ComicCoversView
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

// Comic Pages components
export const ComicPagesList = Loadable({
  loader: () => import('./modules/comicPages/comicPagesList'),
  render(loaded, props) {
    let Component = loaded.ComicPagesList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const ComicPagesView = Loadable({
  loader: () => import('./modules/comicPages/comicPagesView'),
  render(loaded, props) {
    let Component = loaded.ComicPagesView
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const ComicPagesEdit = Loadable({
  loader: () => import('./modules/comicPages/comicPagesEdit'),
  render(loaded, props) {
    let Component = loaded.ComicPagesEdit
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

// Marketing Window Components
export const MarketingWindowView = Loadable({
  loader: () => import('./modules/marketingWindow/marketingWindowView'),
  render(loaded, props) {
    let Component = loaded.MarketingWindowView
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const MarketingWindowEdit = Loadable({
  loader: () => import('./modules/marketingWindow/marketingWindowEdit'),
  render(loaded, props) {
    let Component = loaded.MarketingWindowEdit
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const MarketingWindowList = Loadable({
  loader: () => import('./modules/marketingWindow/marketingWindowList'),
  render(loaded, props) {
    let Component = loaded.MarketingWindowList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const MarketingWindowCreate = Loadable({
  loader: () => import('./modules/marketingWindow/marketingWindowCreate'),
  render(loaded, props) {
    let Component = loaded.MarketingWindowCreate
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const PromoList = Loadable({
  loader: () => import('./modules/promo/promoList'),
  render(loaded, props) {
    let Component = loaded.PromoList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const PromoView = Loadable({
  loader: () => import('./modules/promo/promoView'),
  render(loaded, props) {
    let Component = loaded.PromoView
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const PromotionBannerList = Loadable({
  loader: () => import('./modules/promotionBanner/promotionBannerList'),
  render(loaded, props) {
    let Component = loaded.PromotionBannerList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const PromotionBannerView = Loadable({
  loader: () => import('./modules/promotionBanner/promotionBannerView'),
  render(loaded, props) {
    let Component = loaded.PromotionBannerView
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const PromotionBannerEdit = Loadable({
  loader: () => import('./modules/promotionBanner/promotionBannerEdit'),
  render(loaded, props) {
    let Component = loaded.PromotionBannerEdit
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const PromotionBannerCreate = Loadable({
  loader: () => import('./modules/promotionBanner/promotionBannerCreate'),
  render(loaded, props) {
    let Component = loaded.PromotionBannerCreate
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const KycList = Loadable({
  loader: () => import('./modules/kyc/kycList'),
  render(loaded, props) {
    let Component = loaded.KycList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const KycView = Loadable({
  loader: () => import('./modules/kyc/kycView'),
  render(loaded, props) {
    let Component = loaded.KycView
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const PayoutProfileList = Loadable({
  loader: () => import('./modules/payoutProfile/payoutProfileList'),
  render(loaded, props) {
    let Component = loaded.PayoutProfileList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const PayoutProfileView = Loadable({
  loader: () => import('./modules/payoutProfile/payoutProfileView'),
  render(loaded, props) {
    let Component = loaded.PayoutProfileView
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const PayoutProfileEdit = Loadable({
  loader: () => import('./modules/payoutProfile/payoutProfileEdit'),
  render(loaded, props) {
    let Component = loaded.PayoutProfileEdit
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const PayoutMethodList = Loadable({
  loader: () => import('./modules/payoutMethod/payoutMethodList'),
  render(loaded, props) {
    let Component = loaded.PayoutMethodList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const PayoutMethodView = Loadable({
  loader: () => import('./modules/payoutMethod/payoutMethodView'),
  render(loaded, props) {
    let Component = loaded.PayoutMethodView
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const PayoutMethodEdit = Loadable({
  loader: () => import('./modules/payoutMethod/payoutMethodEdit'),
  render(loaded, props) {
    let Component = loaded.PayoutMethodEdit
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const PayoutTransactionList = Loadable({
  loader: () => import('./modules/payoutTransaction/payoutTransactionList'),
  render(loaded, props) {
    let Component = loaded.PayoutTransactionList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const PayoutTransactionView = Loadable({
  loader: () => import('./modules/payoutTransaction/payoutTransactionView'),
  render(loaded, props) {
    let Component = loaded.PayoutTransactionView
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const PayoutRfiList = Loadable({
  loader: () => import('./modules/payoutRfi/payoutRfiList'),
  render(loaded, props) {
    let Component = loaded.PayoutRfiList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const PayoutRfiResolve = Loadable({
  loader: () => import('./modules/payoutRfi/payoutRfiResolve'),
  render(loaded, props) {
    let Component = loaded.PayoutRfiResolve
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

// MCP components
export const McpUserSummaryList = Loadable({
  loader: () => import('./modules/mcp/mcpUserSummaryList'),
  render(loaded, props) {
    let Component = loaded.McpUserSummaryList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const McpUserSummaryView = Loadable({
  loader: () => import('./modules/mcp/mcpUserSummaryView'),
  render(loaded, props) {
    let Component = loaded.McpUserSummaryView
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const McpTransactionList = Loadable({
  loader: () => import('./modules/mcp/mcpTransactionList'),
  render(loaded, props) {
    let Component = loaded.McpTransactionList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const McpActivityPointValueList = Loadable({
  loader: () => import('./modules/mcp/mcpActivityPointValueList'),
  render(loaded, props) {
    let Component = loaded.McpActivityPointValueList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const McpActivityPointValueEdit = Loadable({
  loader: () => import('./modules/mcp/mcpActivityPointValueEdit'),
  render(loaded, props) {
    let Component = loaded.McpActivityPointValueEdit
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const McpLevelsList = Loadable({
  loader: () => import('./modules/mcp/mcpLevelsList'),
  render(loaded, props) {
    let Component = loaded.McpLevelsList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const McpRanksList = Loadable({
  loader: () => import('./modules/mcp/mcpRanksList'),
  render(loaded, props) {
    let Component = loaded.McpRanksList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

// Kasada components

export const KasadaEventList = Loadable({
  loader: () => import('./modules/kasada/kasadaEventList'),
  render(loaded, props) {
    let Component = loaded.KasadaEventList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const KasadaEventEdit = Loadable({
  loader: () => import('./modules/kasada/kasadaEventEdit'),
  render(loaded, props) {
    let Component = loaded.KasadaEventEdit
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const KasadaEventCreate = Loadable({
  loader: () => import('./modules/kasada/kasadaEventCreate'),
  render(loaded, props) {
    let Component = loaded.KasadaEventCreate
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const KasadaDropEdit = Loadable({
  loader: () => import('./modules/kasada/kasadaDropEdit'),
  render(loaded, props) {
    let Component = loaded.KasadaDropEdit
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const CustomGroupList = Loadable({
  loader: () => import('./modules/customGroups/customGroupList'),
  render(loaded, props) {
    let Component = loaded.CustomGroupList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const CustomGroupEdit = Loadable({
  loader: () => import('./modules/customGroups/customGroupEdit'),
  render(loaded, props) {
    let Component = loaded.CustomGroupEdit
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})
export const CustomGroupCreate = Loadable({
  loader: () => import('./modules/customGroups/customGroupCreate'),
  render(loaded, props) {
    let Component = loaded.CustomGroupCreate
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const RewardsView = Loadable({
  loader: () => import('./modules/rewards/rewardsPendingProcessView'),
  render(loaded, props) {
    let Component = loaded.RewardsView
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const RewardPendingProcessList = Loadable({
  loader: () => import('./modules/rewards/rewardsPendingProcessList'),
  render(loaded, props) {
    let Component = loaded.RewardPendingProcessList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const RewardsPendingProcessCreate = Loadable({
  loader: () => import('./modules/rewards/rewardsPendingProcessCreate'),
  render(loaded, props) {
    let Component = loaded.RewardPendingProcessCreate
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const RewardsPendingProcessView = Loadable({
  loader: () => import('./modules/rewards/rewardsPendingProcessView'),
  render(loaded, props) {
    let Component = loaded.RewardPendingProcessView
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

// Waitlist Segment components
export const WaitlistSegmentsList = Loadable({
  loader: () => import('./modules/waitlistSegments/waitlistSegmentsList'),
  render(loaded, props) {
    let Component = loaded.WaitlistSegmentsList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const WaitlistSegmentsEdit = Loadable({
  loader: () => import('./modules/waitlistSegments/waitlistSegmentsEdit'),
  render(loaded, props) {
    let Component = loaded.WaitlistSegmentsEdit
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const WaitlistSegmentsView = Loadable({
  loader: () => import('./modules/waitlistSegments/waitlistSegmentsView'),
  render(loaded, props) {
    let Component = loaded.WaitlistSegmentsView
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const PromotionalReservationList = Loadable({
  loader: () =>
    import('./modules/promotionalReservation/promotionalReservationList'),
  render(loaded, props) {
    let Component = loaded.PromotionalReservationList
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})

export const PromotionalReservationEdit = Loadable({
  loader: () =>
    import('./modules/promotionalReservation/promotionalReservationEdit'),
  render(loaded, props) {
    let Component = loaded.PromotionalReservationEdit
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})
export const PromotionalReservationCreate = Loadable({
  loader: () =>
    import('./modules/promotionalReservation/promotionalReservationCreate'),
  render(loaded, props) {
    let Component = loaded.PromotionalReservationCreate
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})
export const PromotionalReservationView = Loadable({
  loader: () =>
    import('./modules/promotionalReservation/promotionalReservationView'),
  render(loaded, props) {
    let Component = loaded.PromotionalReservationView
    return <Component {...props} />
  },
  loading() {
    return <CircularProgress />
  },
})
