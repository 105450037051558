import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import * as React from 'react'
import { AppBar, UserMenu } from 'react-admin'
import { ChangePasswordDialog } from '../modules/user/utils/changePasswordDialog'

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginLeft: '20px',
  },
  spacer: {
    flex: 1,
  },
  logo: {
    height: '25px',
    margin: '15px',
  },
})

const CustomUserMenu = (props) => (
  <UserMenu {...props}>
    <ChangePasswordDialog {...props} />
  </UserMenu>
)

const CustomAppBar = (props) => {
  const classes = useStyles()
  return (
    <AppBar {...props} userMenu={<CustomUserMenu />}>
      <Typography
        variant="h5"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <img
        src="/icons/veve-logo.png"
        className={classes.logo}
        alt="veve logo"
      />
      <span className={classes.spacer} />
    </AppBar>
  )
}

export default CustomAppBar
