import React from 'react'
import {
  DateField as RADateField,
  Labeled,
  useRecordContext,
} from 'react-admin'
import { labelFromSource } from './label'
import moment from 'moment'
import get from 'lodash/get'

const DateField = (props) => {
  const tz = props.timezone || 'utc'
  let label = props.label || labelFromSource(props.source)
  label += tz === 'utc' ? ' (UTC)' : ''

  const record = useRecordContext(props)

  // convert unix timestamp to ISO date string
  if (props.fromTimestamp) {
    const timestamp = get(record, props.source)
    if (timestamp) {
      record[props.source] = moment.unix(timestamp).toISOString()
    }
  }

  // show N/A instead of default date if value is empty
  if (!get(record, props.source)) {
    if (props.hideLabel) {
      return <span>N/A</span>
    } else {
      return (
        <Labeled label={label}>
          <span>N/A</span>
        </Labeled>
      )
    }
  }

  if (props.hideLabel) {
    return (
      <RADateField
        source={props.source}
        record={record}
        showTime
        options={{ timeZone: tz }}
      />
    )
  }

  return (
    <Labeled label={label}>
      <RADateField
        source={props.source}
        record={record}
        showTime
        options={{ timeZone: tz }}
      />
    </Labeled>
  )
}

export default DateField
