import {
  FIND_ALL_ACCESSORY_TYPES,
  FIND_ACCESSORY_TYPE,
  FIND_ACCESSORIES_BALANCES_BY_USER_ID,
} from '../queries'
import {
  CREATE_ACCESSORY_TYPES,
  UPDATE_ACCESSORY_TYPE,
  DELETE_ACCESSORY_TYPE,
} from '../mutations'
import { GET_LIST, CREATE, GET_ONE, UPDATE, DELETE } from 'react-admin'

const getAccessoryBalanceList = (params) => {
  return {
    query: FIND_ACCESSORIES_BALANCES_BY_USER_ID,
    variables: { id: params.filter.userId || '' },
    parseResponse: ({ data }) => {
      const { getAccessoriesBalancesByUserId } = data
      return {
        data: getAccessoriesBalancesByUserId.map((element) => {
          return {
            id: element.accessoryTypeId,
            balance: element.balance,
            accessoryTypeName: element.accessoryTypeName,
          }
        }),
        total: getAccessoriesBalancesByUserId.length,
      }
    },
  }
}

const getAccessoryTypeList = {
  query: FIND_ALL_ACCESSORY_TYPES,
  parseResponse: ({ data }) => {
    const { findAllAccessoryTypes } = data
    return {
      data: findAllAccessoryTypes,
      total: findAllAccessoryTypes.length,
    }
  },
}

const getAccessoryType = (id) => {
  return {
    query: FIND_ACCESSORY_TYPE,
    variables: { id },
    parseResponse: ({ data }) => {
      const { getAccessoryType } = data
      return {
        data: {
          ...getAccessoryType,
        },
      }
    },
  }
}

const createAccessoryType = (params) => {
  return {
    query: CREATE_ACCESSORY_TYPES,
    variables: {
      input: {
        ...params.data,
      },
    },
    parseResponse: ({ data }) => ({
      data: { ...params.data, id: data.createAccessoryType.id },
    }),
  }
}

const updateAccessoryType = (params) => {
  return {
    query: UPDATE_ACCESSORY_TYPE,
    variables: {
      input: {
        id: params.data.id,
        description: params.data.description,
        status: params.data.status,
      },
    },
    parseResponse: ({ data }) => ({
      data: { ...params.data, id: data.updateAccessoryType.id },
    }),
  }
}

const deleteAccessoryType = (id) => {
  return {
    query: DELETE_ACCESSORY_TYPE,
    variables: { id },
    parseResponse: ({ data }) => ({
      data: { id: data.deleteAccessoryType.id },
    }),
  }
}

export const accessoryTypeHandle = (fetchType, params) => {
  switch (fetchType) {
    case GET_LIST:
      return getAccessoryTypeList
    case CREATE:
      return createAccessoryType(params)
    case UPDATE:
      return updateAccessoryType(params)
    case GET_ONE:
      return getAccessoryType(params.id)
    case DELETE:
      return deleteAccessoryType(params.id)
    default:
      throw new Error(`Unknown fetchType ${fetchType}`)
  }
}

export const accessoryBalanceHandle = (fetchType, params) => {
  switch (fetchType) {
    case GET_LIST:
      return getAccessoryBalanceList(params)
    default:
      throw new Error(`Unknown fetchType ${fetchType}`)
  }
}
